import { format, parse } from 'date-fns'
import { Ref } from 'vue'
import {
  MediaImageFragment,
  NewsDocument,
  LatestNewsDocument,
  SpecificTagsNewsDocument,
  NewsFieldsFragment,
  LanguageId,
  TaxonomyTermNewsType,
  TermsNewsCategoriesDocument,
} from '../graphql/generated'
import { useTerms } from './useTerms'

interface Link {
  url: string
  target: string
  text: string
}
interface NewsItem {
  id: string
  type: string
  variant: string
  image: MediaImageFragment | undefined
  title: string
  date: string
  description: string
  link: Link
}

const transformResponse = (data: NewsFieldsFragment[]): NewsItem[] => {
  return (
    data?.map((item) => {
      return {
        id: item.entityId || '',
        variant: item.fieldTeaserImage && item.fieldTeaserImage.entity ? 'style_1' : 'style_2',
        image:
          item.fieldTeaserImage && item.fieldTeaserImage.entity
            ? (item.fieldTeaserImage.entity as MediaImageFragment)
            : undefined,
        title: item.title || '',
        type: item.fieldNewsType && item.fieldNewsType.entity ? item.fieldNewsType.entity.name || '' : '',
        date: format(
          parse(item.fieldPublicationDate?.date || '', "yyyy-MM-dd HH:mm:ss 'UTC'", new Date()),
          'dd.MM.yyyy',
        ),
        description: item.fieldTeaserText
          ? item.fieldTeaserText.processed || ''
          : item.fieldLead
            ? item.fieldLead.processed || ''
            : '',
        link: {
          url: item.entityUrl?.path || '',
          target: '',
          text: 'more' || '', // this.$t('news.list.teaser.cta')
        },
      }
    }) || []
  )
}

const getItems = async (
  document: any,
  query: any,
  variables: any,
): Promise<{
  items: NewsItem[]
  total: any
}> => {
  const { data } = await useAsyncData(`news`, async () => {
    const { data } = await query({
      query: document,
      variables,
    })

    return {
      items: transformResponse(data.nodeQuery?.entities as NewsFieldsFragment[]),
      total: data.nodeQuery?.count || 0,
    }
  })

  return data.value ?? { items: [], total: 0 }
}

export const useNews = async (
  activeNewsTypes: Ref<string[]>,
  newsTypes: { value: string; label: string }[],
  activeNewsTags: Ref<string[]>,
) => {
  const route = useRoute()
  const { languageCODE } = useLocale()
  const { clients } = useApollo()

  const page = ref(parseInt(route.query.page as string) || 1)
  const perPage = 6

  const getVariables = () => ({
    offset: perPage * (page.value - 1),
    perPage,
    visibleTags: activeNewsTags.value,
    visibleNewsTypes: activeNewsTypes.value,
    language: languageCODE.value as LanguageId,
  })

  const setQueryParams = async () => {
    const params = {
      //multiple types active possible, so if all types are active, remove type from query
      ...(activeNewsTypes.value && activeNewsTypes.value.length !== newsTypes.length
        ? { type: activeNewsTypes.value }
        : {}),
      //if there are more than 1 tag active, it means all are active, so remove them from the query
      ...(activeNewsTags.value && activeNewsTags.value.length < 2 ? { tag: activeNewsTags.value } : {}),
      page: page.value,
      scroll: 'false',
    }

    await navigateTo({ query: params, replace: true })
  }

  const data = ref(await getItems(NewsDocument, clients!.default.query, getVariables()))

  watch([page, activeNewsTags, activeNewsTypes], async ([nPage], [pPage]) => {
    if (nPage !== 1 && nPage === pPage) return (page.value = 1)
    setQueryParams()
    data.value = await getItems(NewsDocument, clients!.default.query, getVariables())
  })

  const items = computed(() => data.value.items)
  const maxPages = computed(() => Math.ceil(data.value.total / perPage))

  const noResults = computed(() => items.value.length === 0)

  return { items, page, maxPages, noResults } as const
}

export const useNewsLatest = async (count: number = 6, id: string = '0') => {
  const { languageCODE } = useLocale()
  const { clients } = useApollo()

  const getVariables = () => ({
    count: count,
    currentId: id,
    language: languageCODE.value as LanguageId,
  })

  const data = ref(await getItems(LatestNewsDocument, clients!.default.query, getVariables()))
  const items = computed(() => data.value.items)

  return { items } as const
}

export const useNewsSpecific = async (
  count: number = 6,
  visibleTags: string[] = [],
  hiddenTags: string[] = [],
  id: string = '0',
) => {
  const { languageCODE } = useLocale()
  const { clients } = useApollo()

  const getVariables = () => ({
    count: count,
    currentId: id,
    hiddenTags: hiddenTags,
    visibleTags: visibleTags,
    language: languageCODE.value as LanguageId,
  })

  const data = ref(await getItems(SpecificTagsNewsDocument, clients!.default.query, getVariables()))
  const items = computed(() => data.value.items)

  return { items } as const
}

export const useNewsTypes = async () => {
  const { languageCODE } = useLocale()
  const { clients } = useApollo()
  const variables = {
    language: languageCODE.value as LanguageId,
  }

  const { data } = await useAsyncData('terms-news-categories', async () => {
    const { data } = await clients!.default.query({
      query: TermsNewsCategoriesDocument,
      variables,
    })
    return { items: data.taxonomyTermQuery?.entities }
  })

  const options = (data.value?.items || []).map((item) => {
    const { entityId, entityLabel } = item as TaxonomyTermNewsType
    return { value: entityId || '', label: entityLabel || '' }
  })

  return { options } as const
}
